
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"

const SingleProject = () => {
  return (
    <div>
      <Layout>
        <div className="flex flex-col items-center justify-center py-24 px-6 lg:px-16 case_study_bg text-white lg:h-96">
          <h1 className="text-3xl lg:text-6xl font-bold text-center">STAR</h1>
          <p className="lg:w-100 text-xl font-thin pt-8"></p>
        </div>
        <div className="flex items-center justify-center py-8 px-6 lg:px-32">
          <article className=" font-thin py-16 w-full ">
            <div className="flex lg:flex-row flex-col">
              <div className="flex flex-col w-full lg:w-1/2  pr-0 lg:pr-12">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/startrek.gif"
                  placeholder="blurred"
                  className="my-4"
                  layout="constrained"
                  // aspectRatio={5 / 1}
                  style={{
                    gridArea: "1/1",
                    // You can set a maximum height for the image, if you wish.
                    maxHeight: 600,
                    width: 350,
                    height: 150,
                  }}
                />
                <h1 className="text-xl font-bold items-center ">
                  CAMPAIGN OBJECTIVE{" "}
                </h1>

                <div className="">
                  <p className="font-light">
                    Star is a larger beer targeted at youths. It became
                    increasingly important to have a deeper conversion with our
                    audience via all media touchpoints. The Star Trek City Storm
                    biggest idea was to bring top music ambassadors closer to
                    the Grass root target market, with specific most-listened-to
                    music artistes to platforms in 10 focus locations.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full lg:w-1/2  pt-8 lg:pt-0">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/star1.gif"
                  placeholder="blurred"
                  className="my-4"
                  layout="constrained"
                  // aspectRatio={5 / 1}
                  style={{
                    gridArea: "1/3",
                    // You can set a maximum height for the image, if you wish.
                    maxHeight: 600,
                    width: 350,
                    height: 100,
                  }}
                />
                <h1 className="text-xl font-bold items-center ">SOLUTION</h1>

                <div className="">
                  <p className="font-light">
                    We shortlisted the best 2012 9ja songs which led to the
                    selection of artistes for this project. These music stars
                    had to identify their selling point and locations that would
                    drive the audience wild and increase the campaign buzz
                    during this period.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col pt-12">
              <div className="flex flex-col w-full lg:w-1/2 pr-0 lg:pr-12">
                <h1 className="text-xl font-bold items-center ">CONVERSION</h1>
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/star2.gif"
                  placeholder="blurred"
                  className="my-4"
                  layout="constrained"
                  // aspectRatio={5 / 1}
                  style={{
                    gridArea: "1/1",
                    // You can set a maximum height for the image, if you wish.
                    maxHeight: 600,
                    width: 350,
                    height: 200,
                  }}
                /> */}
                <div className="">
                  <p className="font-light">
                    {" "}
                    Our idea was to get customers involved in celebrating the
                    brand with an approx. 6,000 jingles on the radio within a
                    span of 5weeks. DJ/Hype was initiated in all event locations
                    with a minimum of 30 free tickets winners per week. We
                    picked winners via phone-in Q&A.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full lg:w-1/2 pt-8 lg:pt-0">
                <h1 className="text-xl font-bold items-center ">RESULT</h1>
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/startrek.gif"
                  placeholder="blurred"
                  className="my-4"
                  layout="constrained"
                  // aspectRatio={5 / 1}
                  style={{
                    gridArea: "1/1",
                    // You can set a maximum height for the image, if you wish.
                    maxHeight: 600,
                    width: 450,
                    height: 150,
                  }}
                /> */}
                <div className="">
                  <p className="font-light">
                    Brand project awareness increased immensely due to more
                    market activation. The brand Facebook page increased by over
                    500%. Mobile and social campaign pages were developed to
                    support this project. The brand loved the deeper level of
                    conversation and the level of energy and affinity between
                    the consumer and star.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    </div>
  )
}

export default SingleProject
